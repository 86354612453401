<template>
	<el-dialog :title="'申请检验项目'" :close-on-click-modal="false" :visible.sync="visible"
		width="950px">
		<el-form ref="dataForm" :model="dataForm" inline label-width="80px" label-position="left">
			<el-row>
<!--				<el-col :span="8">-->
<!--					<el-form-item label="标的物">-->
<!--						<el-select class="selItemInput" v-model="dataForm.subjectNo" -->
<!--							placeholder="请选择">-->
<!--							<el-option v-for="item in subjectList" :key="item.displayValue" :label="item.displayName"-->
<!--								:value="item.displayValue">-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="12">
					<el-form-item label="专业">
						<el-select class="selItemInput" v-model="dataForm.major" placeholder="请选择" :disabled="true">
							<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
<!--				<el-col :span="12">-->
<!--					<el-form-item label="检验项目">-->
<!--						<el-select class="selItemInput" v-model="dataForm.testItem" placeholder="请选择" :disabled="true">-->
<!--							<el-option v-for="item in testItemList" :key="item.displayValue" :label="item.displayName"-->
<!--								:value="item.displayValue">-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="12">
					<el-form-item label="申请时间">
						<el-date-picker class="selItemInput" v-model="dataForm.applyDate" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
<!--				<el-col :span="12">-->
<!--					<el-form-item label="申请人">-->
<!--						<el-input class="selItemInput" type="input" v-model="dataForm.applyMan" placeholder="请填写">-->
<!--						</el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="8">-->
<!--					<el-form-item label="检验时间">-->
<!--						<el-date-picker class="selItemInput" v-model="dataForm.testDate" type="date"-->
<!--							value-format="yyyy-MM-dd" placeholder="选择日期">-->
<!--						</el-date-picker>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="8">-->
<!--					<el-form-item label="检验人">-->
<!--						<el-input class="selItemInput" type="input" v-model="dataForm.testMan" placeholder="请填写">-->
<!--						</el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="8">-->
<!--					<el-form-item label="检验结论">-->
<!--						<el-select class="selItemInput" v-model="dataForm.testConclusion" placeholder="请选择">-->
<!--							<el-option v-for="item in conclusionList" :key="item.displayValue" :label="item.displayName"-->
<!--								:value="item.displayValue">-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="24">
					<el-form-item label="检验内容">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
							v-model="dataForm.probContext" placeholder="描述检验内容"></el-input>
					</el-form-item>
				</el-col>
<!--				<el-col :span="8">-->
<!--					<el-form-item label="整改意见">-->
<!--						<el-input class="selItemInput" type="textarea" rows="5" v-model="dataForm.sutContent">-->
<!--						</el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="24">-->
<!--					<el-form-item label="现场照片">-->
<!--						<div class="addImgBox" v-for="(item,index) in dataForm.list">-->
<!--							<el-image style="width: 100%; height: 100%" :src="item.photoPathUri" fit="cover"></el-image>-->
<!--							<div class="delBox">-->
<!--								<i class="el-icon-delete" @click="delImg(index)"></i>-->
<!--							</div>-->
<!--						</div>-->
<!--						<el-upload class="addImgBox" ref="uploadImg" :action="action" :http-request="uploadImg"-->
<!--							accept="image/jpeg,image/png,image/jpg">-->
<!--							<el-image style="width: 100%; height: 100%" :src="addImg" fit="cover">-->
<!--							</el-image>-->
<!--						</el-upload>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import img001 from "@/assets/images/001.jpg"
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
		name: "information-apply",
		data() {
			return {
				category: '',
				action: "",
				conclusionList: [],
				subjectList: [],
				majorList: [],
				addImg: addImg,
				visible: true,
				form: {},
				dataForm: {
					workNo: $common.getItem("workNo"),
					testId: '',
					name: "0",
					subjectNo: $common.getItem("subjectNo"),
					desc: "",
					major: '',
					list: [],
					applyDate: '',
					applyMan: '',
					testConclusion: '',
					testDate: '',
					testMan: '',
					//标准
					testReferences: '',
					probContext: '',
					category: '',
				}
			};
		},
		components: {

		},
		// mounted() {
		// 	this.getSubjectDropDown();
		// },
		methods: {
			getCurrentTime() {
				//获取当前时间并打印
				var _this = this;
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth()+1;
				let dd = new Date().getDate();
				let hh = new Date().getHours();
				let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
				let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
				return yy+'-'+mm+'-'+dd;
			},
			init(major,probContext,category) {
				// this.getPersonMajor();
				// this.getSubjectDropDown();
				this.getConclusion();
				this.dataForm = {
					workNo: $common.getItem("workNo"),
					name: "0",
					subjectNo: $common.getItem("subjectNo"),
					desc: "",
					major: '',
					applyDate: '',
					list: [],
					applyMan: '',
					testConclusion: '',
					testDate: '',
					testMan: '',
					//标准
					testReferences: '',
					probContext:'',
					category: category,
				}
				this.dataForm.major = major;
				this.dataForm.probContext = probContext;
				this.category = category;
				this.dataForm.applyDate = this.getCurrentTime();
				this.dataForm.applyMan = $common.getItem("userName");
				this.getMajorList();
				// if (id) {
				// 	this.getDetail();
				// }
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			//标的物
			// getSubjectDropDown() {
			// 	this.$http({
			// 		url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
			// 		method: "get",
			// 		params: {},
			// 	}).then(({
			// 		data
			// 	}) => {
			// 		if (data && data.resultCode === 200) {
			// 			this.subjectList = data.body;
			// 		}
			// 	});
			// },

			getMajorList() {
				this.$http({
					url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
					method: "get",
					params: {},
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.majorList = data.body;
					}
				});
			},
			getDetail() {
				this.$http({
					url: this.$store.state.httpUrl + "/business/testrecord/info/" + this.dataForm.testId,
					method: "get",
					params: {},
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.dataForm = data.body
					}
				});
			},
			//报验结论
			getConclusion() {
				this.$http({
					url: this.$store.state.httpUrl + "/common/getInspectionConclusion",
					method: "get",
					params: {},
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.conclusionList = data.body;
					}
				});
			},
			//图片
			delImg(index) {
				let _list = this.dataForm.list;
				_list.splice(index, 1);
				this.dataForm.list = _list;
			},
			uploadImg(param) {
				const formData = new FormData();
				formData.append('file', param.file);
				this.$refs.uploadImg.clearFiles();
				this.$http.post(
					this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/10/0",
					formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					},
				).then(res => {
					res = res.data
					if (res.resultCode == 200) {
						this.dataForm.list.push({
							photoName: res.body.fileName,
							photoPath: res.body.fileSavePath,
							photoPathUri: res.body.fileUri
						});
					} else {
						this.$message.info('文件上传失败')
					}
				})
			},

			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						let _url = "/business/testrecord/save";
						// if (this.dataForm.testId && this.dataForm.testId != 0) {
						// 	_url = "/business/testrecord/update";
						// }
						this.$http({
							url: this.$store.state.httpUrl + _url,
							method: "post",
							data: this.$http.adornData(this.dataForm)
						}).then(({
							data
						}) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$parent.getDataList();
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			},

		}
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg {
		width: 718px !important;
	}

	.addImgBox {
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}

	.fondstyle {
		color: #3f649f;
		font-size: 15px;
	}
</style>
